import {
    Component,
    HostListener,
    ViewChild,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { StatisticsService } from '../../shared/services/statistics.service';
import * as Highcharts from 'highcharts';
import { Subscription } from "rxjs";
import { BasicUser } from '../../shared/models/user.model';

@Component({
    selector: 'app-dashboard-test-count-widget',
    templateUrl: './dashboard-test-count-widget.component.html',
    styleUrls: ['dashboard-test-count-widget.component.scss', '../shared.scss']
})

export class DashboardTestCountWidgetComponent implements AfterViewInit, OnChanges {

    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild('chartContainer', {static: false}) chartContainer;

    chart: Highcharts.Chart;
    chartOptions: Highcharts.Options = {
        chart: {height: 190, style: {fontFamily: StatisticsService.chartFontFamily}},
        title: {text: 'Test sent', x: -20, style: {display: 'none'}},
        colors: StatisticsService.colors,
        xAxis: {
            type: "datetime",
            gridLineWidth: 1,
            tickWidth: 0,
            allowDecimals: false,
            crosshair: StatisticsService.chartCrosshair
        },
        yAxis: [{title: {text: null}}],
        tooltip: StatisticsService.chartTooltip,
        legend: {align: 'center', borderWidth: 0},
        series: [
            {type: 'areaspline', name: 'Tests (submitted)', lineWidth: 1, marker: {radius: 2}, data: []},
            {type: 'areaspline', name: 'Tests (billed)', lineWidth: 1, marker: {radius: 2}, data: []},
        ],
        credits: { enabled: false },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.3,
            }
        }
    };

    @Input() from: string;
    @Input() to: string;
    @Input() users: BasicUser[];

    loading = true;

    private request: Subscription;

    @HostListener('window:resize') onResize() {this.resize();}
    resize() {
        if (!this.chartContainer || !this.chart) {return;}
        this.chart.setSize(this.chartContainer.nativeElement.offsetWidth, <number>this.chartOptions.chart.height);
    }

    constructor(
        public statistics: StatisticsService,
        public notificationService: NotificationService
    ) {
    }


    ngAfterViewInit() {
        this.resize();
        this.update();
    }

    update() {
        this.loading = true;
        if (this.request && !this.request.closed) {this.request.unsubscribe();}
        this.request = this.statistics.testCount(this.from, this.to, this.users).subscribe({
            next: data => {
                this.loading = false;
                if (!this.chart) {return;}
                this.chart.hideLoading();
                if (data.billed.length || data.submitted.length) {
                    this.chart.update({
                        xAxis: {tickInterval: data.tickInterval, labels: {step: data.labelStep}},
                        series: [
                            {type: 'areaspline', data: data.submitted},
                            {type: 'areaspline', data: data.billed},
                        ]
                    });
                } else {
                    this.chart.showLoading('No data');
                }
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Dashboard',
                    message: 'An error occurred while loading statistics',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                this.chart.showLoading('Error on loading');
            }
        });
    }

    setChart(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.users && changes.users.previousValue !== undefined) ||
            (changes.from && changes.from.previousValue !== undefined) ||
            (changes.to && changes.to.previousValue !== undefined)
        ) {
            this.update();
        }
    }
}
