
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
    AfterViewInit, Component, ElementRef, EventEmitter, HostListener,
    Input, isDevMode, OnDestroy, Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable, forkJoin, of, throwError } from "rxjs";
import { catchError, debounceTime, map } from "rxjs/operators";
import { SessionSupplier, SessionSupplierCollection } from '../../shared/models/session-supplier.model';
import { Session, SessionsCollection } from "../../shared/models/session.model";
import { Attribute } from '../../shared/models/supplier.model';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from '../../shared/services/notification.service';
import { AllRequestParams, SessionsService } from '../../shared/services/sessions.service';
import { SuppliersService } from '../../shared/services/suppliers.service';
import { UsersService } from '../../shared/services/users.service';

@Component({
    selector: 'app-routes-select',
    templateUrl: 'routes-select.component.html',
    styleUrls: ['routes-select.component.scss']
})

export class RoutesSelectComponent implements OnDestroy, AfterViewInit {

    @Input() updatePause = false;
    @Input() myConnections = false;
    @Input() isFilter: boolean = false;

    @Output() onFilterChange = new EventEmitter;
    @Output() onChange = new EventEmitter;

    @ViewChild('createModalTpl', { read: TemplateRef, static: false }) createModalTpl: any;
    @ViewChild('connectionImportModalTpl', { read: TemplateRef, static: false }) connectionImportModalTpl: any;
    @ViewChild('selectSessionModalTpl', { read: TemplateRef, static: false }) selectSessionModalTpl: any;

    createModal: DialogRef;
    connectionModal: DialogRef;
    selectSessionModal: DialogRef;

    models: SessionSupplier[] = [];
    private modelsIndexes = new SessionSupplierMap();
    favModels: SessionSupplier[] = [];
    private favModelsIndexes = new SessionSupplierMap();

    attributeFilterModel: AttributeType[] = [];
    private selectAfterUpdate: SessionSupplier[] = [];
    toggleAll = false;
    toggleAllFavourites = false;
    loading = false;
    searchQuery = '';
    totalElements = 0;
    searchControl: FormControl = new FormControl();
    sessionSearchControl: FormControl = new FormControl();

    requestParams = new AllRequestParams();

    updateScheduler;
    schedulerInterval = 30 * 1000;

    selectAllDisabled = false;
    isAllDisabled: boolean = false;
    importConnectionFileList: any;
    connectionsFromExcelFile: string[] = [];

    availableSessions: Session[] = [];
    selectedSession: Session;

    @ViewChild('elWrapper') elWrapper: ElementRef;
    rowsVisible = 8;

    @HostListener('window:resize')
    onResize() {
        if (!this.elWrapper) { return; }
        const height = this.elWrapper.nativeElement.clientHeight - 30;
        if (!height && height <= 0) { return; }
        const rows = Math.max(Math.floor(height / 30), 8);
        if (isNaN(rows)) {
            console.error('Destination list Invalid size: ', rows);
        } else {
            if (rows !== this.rowsVisible) {
                this.rowsVisible = rows;
            }
        }
    }

    @ViewChild('scrollViewportSelected') private cdkVirtualScrollViewportSelected: CdkVirtualScrollViewport;
    @ViewChild('scrollViewportAll') private cdkVirtualScrollViewportAll: CdkVirtualScrollViewport;
    @ViewChild('scrollViewportFavorites') private cdkVirtualScrollViewportFavorites: CdkVirtualScrollViewport;
    onChangeTab() {
        setTimeout(() => {
            this.cdkVirtualScrollViewportSelected?.checkViewportSize();
            this.cdkVirtualScrollViewportAll?.checkViewportSize();
            this.cdkVirtualScrollViewportFavorites?.checkViewportSize();
        }, 100);
    }

    constructor(
        public connectionService: SessionsService,
        public suppliers: SuppliersService,
        private sessionService: SessionsService,
        public userService: UsersService,
        public modal: ModalService,
        public localStorage: LocalStorageService,
        private notificationService: NotificationService,
        private router: Router
    ) {
        this.modal = modal;
        this.requestParams.page = 0;
        this.requestParams.size = isDevMode() ? 30 : 200;
        this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.searchQuery = this.searchControl.value.trim();
            this.requestParams.search = this.searchQuery;
            this.requestParams.page = 0;
            this.requestParams.shouldSearchHidden = this.isFilter ? true : false;
            this.selectAfterUpdate = this.models.filter(_ => _.selected).map(_ => Object.assign({}, _));
            this.models = [];
            this.attributeFilterModel.forEach(afm => afm.isSelected = false);
            this.requestParams.attributes = null;
            this.update(false, false);
        });
        this.sessionSearchControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.updateSessions();
        });
        userService.can('admin').then(isAdmin => this.selectAllDisabled = isAdmin).catch(e => this.selectAllDisabled = true);
    }

    ngAfterViewInit(): void {
        if (this.isFilter) {
            const savedUserSearchDetails = this.localStorage.get(`lnt_user_search_${this.userService.authUser.id}_search`);
            this.update(false, false, savedUserSearchDetails);
        }
        this.onResize();
        this.onChangeTab();
        this.populateAttributeFilters();
        if (!this.myConnections) {
            this.connectionService.asssignmentsFavourites().subscribe(page => {
                this.favModels = page.content;
                this.reindexFav();
            });
        }
    }

    populateAttributeFilters() {
        SuppliersService.ATTRIBUTE_OPTIONS.forEach(attribute => {
            attribute.forEach(_ => {
                this.attributeFilterModel.push({
                    id: _.id,
                    icon: _.icon,
                    label: _.label,
                    isSelected: false,
                    isEnabled: true
                });
            });
        })
    }

    getSelectedModels() {
        const selected = new Map<string, SessionSupplier>();
        [
            ...this.models.filter(_ => _.selected),
            ...this.favModels.filter(_ => _.selected)
        ].forEach(s => {
            const key = `${s.sessionId}-${s.supplierId}`;
            s.selected = true;
            selected.set(key, s);
        });
        return Array.from(selected.values());
    }

    getFavouriteModels() {
        return this.favModels;
    }

    reset() {
        this.models.map(_ => _.selected = false);
        this.favModels.map(_ => _.selected = false);
        this.searchControl.patchValue('');
        this.searchQuery = '';
        this.toggleAll = false;
        this.toggleAllFavourites = false;
    }

    update(returnObservable = false, loading = true, model: any = null): any {
        if (this.updatePause) {
            return returnObservable ? of([]) : null;
        }

        if (loading) {
            this.loading = true;
        }

        if (!this.updateScheduler && !this.myConnections) {
            this.updateScheduler = setInterval(this.createScheduler(), this.schedulerInterval);
        }

        const successHandler = data => {
            const processedSuppliers = data.content.map((c: SessionSupplier) => {
                c.selected = model?.sessionSupplierIds?.some(ss => ss.supplierId === c.supplierId && ss.sessionId === c.sessionId) || false;
                return c;
            });

            this.models = [...this.models, ...this.getSuppliersInOrder(processedSuppliers)];
            this.checkDuplicates();
            this.totalElements = data.totalElements;
            this.modelsIndexes = new SessionSupplierMap();
            this.models.forEach((c, i) => this.modelsIndexes.set(c, i));
            if (this.selectAfterUpdate.length) {
                for (let params of this.selectAfterUpdate) {
                    this.selectByParams(params.sessionId, params.supplierId, params);
                }
                this.selectAfterUpdate = [];
            }

            if (loading) {
                this.loading = false;
            }
        }

        const errorHandler = (res) => {
            if (loading) {
                this.loading = false;
            }
            return throwError(() => new Error(res));
        }

        const updateRequest = this.connectionService.assingments(this.requestParams).pipe(
            map(successHandler),
            catchError(errorHandler)
        );
        return returnObservable ? updateRequest : updateRequest.subscribe();
    }

    checkDuplicates() {
        const sessionSupplierIds = new Set();
        for (let i = 0; i < this.models.length; i++) {
            const sessionSupplierId = `${this.models[i].sessionId}-${this.models[i].supplierId}`;
            if (sessionSupplierIds.has(sessionSupplierId)) {
                this.models.splice(i, 1);
                i--;
            } else {
                sessionSupplierIds.add(sessionSupplierId);
            }
        }
    }

    updateSessions(): void {
        const params = new AllRequestParams();
        params.size = 10;
        params.page = 0;
        params.search = this.sessionSearchControl.value.trim();
        params.setSort('id', 'desc');
        this.sessionService.all(params).subscribe((sc: SessionsCollection) => {
            this.availableSessions = sc.content;
        });
    }

    createScheduler() {
        return () => {
            this.connectionService.assingments(this.requestParams).subscribe((response: SessionSupplierCollection) => {
                response.content.map((sessionSupplier: SessionSupplier) => {
                    const sessionId = sessionSupplier.sessionId;
                    const online =  sessionSupplier.online;
                    this.models.filter(_ => _.sessionId === sessionId && _.online !== online)
                        .forEach(s => s.online = online);
                    this.favModels.filter(_ => _.sessionId === sessionId && _.online !== online)
                        .forEach(s => s.online = online);
                });
            }, error => { });
        };
    }

    onScrolledIndexChange(index: number) {
        if (!index || !this.models.length) { return; }
        const currentIndex = index + this.rowsVisible;
        if (currentIndex === this.totalElements) {
            this.requestParams.page++;
            this.update(false, false);
        }
    }

    onClickToggleAllFavorites() {
        this.toggleAll = false;
        this.toggleAllFavourites = !this.toggleAllFavourites;
        this.getFavouriteModels().forEach(c => {
            this.toggle(c, this.toggleAllFavourites, false);
        });
        this.onChange.emit();
    }

    onClickToggleAll() {
        if (this.selectAllDisabled) { return; }
        this.toggleAll = !this.toggleAll;
        this.toggleAllFavourites = this.toggleAll;
        const selectAll = (val) => {
            this.models.filter(_ => {
                if (this.myConnections) {
                    return _.selected !== val;
                }
                return _.selected !== val && _.online;
            }).map(c => this.toggle(c, val, false));
            this.onChange.emit();
        };
        if (this.toggleAll && this.models.length < this.totalElements) {
            this.requestParams.size = this.totalElements;
            this.requestParams.page = 0;
            this.selectAfterUpdate = this.models.filter(_ => _.selected).map(_ => Object.assign({}, _));
            this.models = [];
            (this.update(true, true) as Observable<any>).subscribe(() => {
                selectAll(this.toggleAll);
            });
        } else {
            selectAll(this.toggleAll);
        }
    }

    toggle(connection: SessionSupplier, value: boolean|null = null, emmit = true) {
        if (this.isAllDisabled) return;
        if (!this.myConnections && !connection.online) {
            return;
        }
        const mainConn = this.modelsIndexes.has(connection) ? this.modelsIndexes.get(connection) : null;
        const favConn =  this.favModelsIndexes.has(connection) ? this.favModelsIndexes.get(connection) : null;
        const selected= value === null ? !connection.selected : value;

        if (mainConn !== null && this.models[mainConn]) {
            this.models[mainConn].selected = selected;
        }
        if (favConn !== null) {
            this.favModels[favConn].selected = selected;
        }
        if (!selected && this.toggleAll) {
            this.toggleAll = false;
        }
        if (!selected && this.toggleAllFavourites) {
            this.toggleAllFavourites = false;
        }
        if (emmit) {
            this.onChange.emit();
        }
    }

    selectByParams(connectionId, supplierId, notPresentModel: SessionSupplier = null) {
        let toggleModels = this.models.filter(c => !c.selected && c.sessionId === connectionId && c.supplierId === supplierId);
        if (!toggleModels.length) {
            if (notPresentModel) {
                notPresentModel.selected = false;
                this.models.push(notPresentModel);
                this.modelsIndexes.set(notPresentModel, this.models.length - 1);
                this.toggle(notPresentModel);
            } else {
                forkJoin([
                    this.connectionService.one(connectionId),
                    this.suppliers.one(supplierId)
                ]).subscribe(_ => {
                    const model = {
                        title: _[1].title,
                        routeType: _[1].routeType,
                        online: _[0].online,
                        sessionId: _[0].id,
                        supplierId: _[1].id,
                        selected: false,
                        isFavourite: false
                    };
                    this.models.push(model);
                    this.modelsIndexes.set(model, this.models.length - 1);
                    this.toggle(model);
                });
            }
        } else {
            toggleModels.map(c => this.toggle(c));
        }
    }

    onClickCreate() {
        const params = new AllRequestParams();
        params.size = 10;
        params.page = 0;
        params.setSort('id', 'desc');

        this.sessionService.all(params).subscribe((sc: SessionsCollection) => {
            this.availableSessions = sc.content;
            if (sc.totalElements === 0) {
                this.router.navigate(['/sessions']);
            } else if (sc.totalElements === 1) {
                this.sessionService.setActiveSessionForSupplier(sc.content[0]);
                this.createModal = this.modal.alert().size('modal-dialog max-large-modal').component(this.createModalTpl).open();
                this.createModal.onDestroy.subscribe((_) => {
                    this.update(false, true, null);
                });
            } else {
                this.selectSessionModal = this.modal.alert().size('modal-dialog large-modal').component(this.selectSessionModalTpl).open();
                this.selectSessionModal.onDestroy.subscribe((_) => {
                    this.availableSessions = [];
                    this.selectedSession = null;
                });
            }
        });
    }

    selectSupplier(): void {
        this.sessionService.setActiveSessionForSupplier(this.selectedSession);
        this.selectSessionModal.close();
        this.createModal = this.modal.alert().size('modal-dialog max-large-modal').component(this.createModalTpl).open();
        this.createModal.onDestroy.subscribe((_) => {
            this.update(false, true, null);
        });
    }

    onAfterSave() {
        this.loading = true;
        this.createModal.close(true);
        this.selectAfterUpdate = this.getSelectedModels().map(_ => Object.assign({}, _));
        this.update();
    }

    getSuppliersInOrder(suppliers: SessionSupplier[]): SessionSupplier[] {
        let onlineSuppliers: SessionSupplier[] = [];
        let offlineSuppliers: SessionSupplier[] = [];
        suppliers.map((supplier: SessionSupplier) => {
            if (supplier.online) {
                onlineSuppliers.push(supplier);
            } else {
                offlineSuppliers.push(supplier);
            }
        });
        // Using collator here for handling very huge sized arrays without performance hit. 
        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        onlineSuppliers = onlineSuppliers.sort((a, b) => collator.compare(a.title, b.title));
        offlineSuppliers = offlineSuppliers.sort((a, b) => collator.compare(a.title, b.title))
        return [...onlineSuppliers, ...offlineSuppliers];
    }

    uploadExcelIconClick(event: any): void {
        this.connectionModal = this.modal.alert().component(this.connectionImportModalTpl).open();
    }

    onImportConnection(event: any): void {
        this.connectionsFromExcelFile = event;
        this.models.forEach(_ => _.selected = false);
        this.models.filter(_ => _.online).forEach(connection => {
            const titleRouteType = `${connection.title}_${connection.routeType}`.trim();
            if (this.connectionsFromExcelFile.includes(titleRouteType)) {
                connection.selected = true;
            } else {
                connection.selected = false;
            }
        });
        this.onChange.emit();
        this.connectionModal.close();
    }

    onDeleteFilterClick() {
        if (this.getSelectedModels().length > 0) {
            this.onFilterChange.emit(null);
        }
        this.reset();
        this.onChange.emit();
    }

    getAttributeIcon(id: string): string | null {
        for (let group of SuppliersService.ATTRIBUTE_OPTIONS) {
            for (let attribute of group) {
                if (attribute.id === id) {
                    return attribute.icon;
                }
            }
        }
        return null;
    }

    onFavChange(connection: SessionSupplier) {
        const sessionId = connection.sessionId;
        const supplierId = connection.supplierId;
        const favIndex=  this.favModelsIndexes.has(connection) ? this.favModelsIndexes.get(connection) : null;
        const mainIndex=  this.modelsIndexes.has(connection) ? this.modelsIndexes.get(connection) : null;
        const isFavourite = !connection.isFavourite;
        if (favIndex !== null) {
            this.favModels[favIndex].isFavourite = isFavourite;
        }
        if (mainIndex !== null) {
            this.models[mainIndex].isFavourite = isFavourite;
        }
        if (favIndex !== null && mainIndex === null && !isFavourite && connection.selected) {
            this.toggle(connection);
        }
        this.markAsFav(sessionId, supplierId, isFavourite).pipe(
            catchError(err => {
                if (favIndex !== null) {
                    this.favModels[favIndex].isFavourite = !isFavourite;
                }
                if (mainIndex !== null) {
                    this.models[mainIndex].isFavourite = !isFavourite;
                }
                return throwError(() => err);
            }),
            map(() => {
                if (isFavourite && favIndex === null) {
                    this.favModels = [...this.favModels, connection];
                    this.reindexFav();
                }
                if (!isFavourite && favIndex !== null) {
                    this.favModels = [...this.favModels.filter(_ => _ !== this.favModels[favIndex])];
                    this.reindexFav();
                }
            })
        ).subscribe();
    }

    private markAsFav(sessionId: number, supplierId: number, val: boolean) {
        return this.connectionService.onFavouriteChange(sessionId, supplierId, val).pipe(
            map(() => {
                this.notificationService.success('Favourite Supplier Updated', 'Suppliers');
            }),
            catchError(err => {
                this.notificationService.error({
                    title: 'Supplier',
                    message: 'There was a problem marking/unmarking this supplier as favourite.',
                    service: 'LNT'
                });
                return throwError(() => err);
            })
        );
    }

    getAttributeName(attributeId: string): string {
        return SuppliersService.ATTRIBUTE_TO_LABEL[attributeId];
    }

    isAttributeFilterSelected(): boolean {
        return this.attributeFilterModel.filter(_ => _.isSelected).length > 0;
    }

    onFilterAttributeClick(clickedAttribute: AttributeType): void {
        clickedAttribute.isSelected = !clickedAttribute.isSelected;
        const disableRule = SuppliersService.attrDisableRules[clickedAttribute.id];
        if (disableRule) {
            this.attributeFilterModel.forEach(attr => {
                if (attr.id === disableRule) {
                    attr.isEnabled = !clickedAttribute.isSelected;
                }
            });
        }
        const selectedAttributeIds = this.attributeFilterModel
            .filter(_ => _.isSelected)
            .map(_ => _.id)
            .join(',');
        this.requestParams.attributes = selectedAttributeIds;
        this.requestParams.page = 0;

        this.selectAfterUpdate = this.models.filter(_ => _.selected).map(_ => Object.assign({}, _));
        this.models = [];
        this.searchQuery = '';
        this.requestParams.search = '';

        this.update();
    }


    onUnselectAllAttributeFilter(): void {
        this.attributeFilterModel.forEach(_ => { _.isSelected = false; _.isEnabled = true });
        this.requestParams.attributes = null;
        this.requestParams.page = 0;

        this.selectAfterUpdate = this.models.filter(_ => _.selected).map(_ => Object.assign({}, _));
        this.models = [];
        this.searchQuery = '';
        this.requestParams.search = '';

        this.update();
    }

    getTooltip(connection) {
        const tooltipText = `${connection.title} (${connection.routeType})`;
        if (tooltipText.length > 30) {
            return tooltipText;
        }
        return null;
    }

    ngOnDestroy() {
        if (this.updateScheduler) {
            clearInterval(this.updateScheduler);
        }
    }

    private reindexFav() {
        this.favModelsIndexes = new SessionSupplierMap();
        this.favModels = this.favModels.sort((a,b) => (b.online ? 1 : 0) - (a.online ? 1 : 0));
        this.favModels.forEach((s, i) => this.favModelsIndexes.set(s, i));
    }
}


type AttributeType = Attribute & { isSelected: boolean, isEnabled: boolean };

type SessionSupplierMapKey = {sessionId: number, supplierId: number};

class SessionSupplierMap {
    private m = new Map<string, number>();
    get(s: SessionSupplierMapKey) {
        return this.m.get(this.createKey(s));
    }
    set(s: SessionSupplierMapKey, val: number) {
        return this.m.set(this.createKey(s), val);
    }
    has(s: SessionSupplierMapKey) {
        return this.m.has(this.createKey(s));
    }
    delete(s: SessionSupplier) {
        return this.m.delete(this.createKey(s));
    }
    private createKey(s: SessionSupplierMapKey) {
        return `${s.sessionId}-${s.supplierId}`;
    }
}