
import {
    Component,
    ViewChild,
    EventEmitter,
    TemplateRef, OnDestroy,
} from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';
import { CountryService } from '../../shared/services/country.service';
import { NotificationService } from '../../shared/services/notification.service';
import { AccountUser, BasicUser, MfaSetup, PaymentType, Role } from '../../shared/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../shared/services/users.service';
import { NewEmailModel, UpdatePasswordModel } from '../../shared/models/user.model';
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { BrowserUtils } from "../../shared/services/browser-utils";
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { catchError } from "rxjs/operators";
import { CancelError, ConfirmedActionsService } from "../../shared/services/confirmed-actions.service";
import { defaultLogger } from "@angular/cdk/schematics/update-tool/logger";

@Component({
    selector: 'app-account-form',
    templateUrl: 'account-form.component.html',
    styleUrls: ['./account-form.component.scss']
})

export class AccountFormComponent {

    @ViewChild('passwordModalTpl', {read: TemplateRef, static: false}) passwordModalTpl: any;
    @ViewChild('emailModalTpl', {read: TemplateRef, static: false}) emailModalTpl: any;
    @ViewChild('devicesModalTpl', {read: TemplateRef, static: false}) devicesModalTpl: any;
    @ViewChild('mfaSetupModalTpl', {read: TemplateRef, static: false}) mfaSetupModalTpl: any;
    @ViewChild('disableMfaModalTpl', {read: TemplateRef, static: false}) disableMfaModalTpl: any;

    passwordModal: DialogRef;
    emailModal: DialogRef;
    mfaModal: DialogRef;
    mfaDisableModal: DialogRef;

    loading: boolean = true;
    model: AccountUser;
    countries = [];
    onAfterSave = new EventEmitter();
    backButtonShow = true;

    currentPasswordType: string = 'password';
    newPasswordType: string = 'password';
    passwordModel: UpdatePasswordModel = {
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: ''
    };
    emailModel: NewEmailModel = {
        newEmail: ''
    }

    mfaLoading = false;
    mfaModel = {mfaCode: ''}
    mfaSetup: MfaSetup;
    mfaForm: FormGroup;

    form: FormGroup;
    passwordForm: FormGroup;
    emailForm: FormGroup;
    ipWhitelistForm: FormGroup;

    ipWhitelistAddressList: string[] = [];
    parentAccountDetails: BasicUser = null;
    controlsDisabledForSub = [
        "companyName", "websiteUrl", "phone", "vatNumber", "addressZip",
        "countryName", "addressCity", "addressState", "addressStreet"
    ];

    getVatNumberRequired() {
        return this.form.get('vatNumber').hasValidator(Validators.required)
    }

    constructor(
        public notificationService: NotificationService,
        public formBuilder: FormBuilder,
        public service: UsersService,
        public countryService: CountryService,
        public validationService: ValidationService,
        public modal: ModalService,
        public router: Router,
        private route: ActivatedRoute
    ) {
        this.service.i().subscribe(user => {
            if (user.role === Role.SUB || user.role === Role.DEPUTY) {
                this.service.getParentAccountDetails().subscribe({
                    next: (user: BasicUser) => {
                        this.parentAccountDetails = user;
                    }
                });
            }
            user.testTtl = user.testTtlMin;
            this.model = {
                id: user.id,
                role: user.role,
                email: user.email,
                paymentType: user.paymentType,
                username: user.username,
                fullName: user.fullName,
                companyName: user.companyName,
                phone: user.phone,
                websiteUrl: user.websiteUrl,
                vatNumber: user.vatNumber,
                emailForAlert: user.emailForAlert,
                emailForInvoice: user.emailForInvoice,
                countryName: user.countryName,
                addressCity: user.addressCity,
                addressZip: user.addressZip,
                addressStreet: user.addressStreet,
                addressState: user.addressState,
                testTtl: user.testTtl,
                minBalance: user.minBalance,
                testRetriesEnabled: user.testRetriesEnabled,
                password: null,
                mfaEnabled: user.mfaEnabled ? user.mfaEnabled : false,
                ipWhitelistAddressList: user.ipWhitelistAddressList,
                ipWhitelistEnabled: user.ipWhitelistEnabled
            };
            if (this.model.ipWhitelistAddressList != null && this.model.ipWhitelistAddressList.length > 0) {
                this.model.ipWhitelistAddressList.split(',').forEach(address => {
                    this.ipWhitelistAddressList.push(address);
                });
            }
            if (!this.shouldMinBalanceBeShown()) {
                this.form.removeControl('minBalance');
            }
            countryService.all().then(countries => {
                if (this.hasParentAccount()) {
                    this.form.controls.companyName.patchValue(user.companyName);
                    this.form.controls.testTtl.patchValue(user.testTtl);
                }
                this.loading = false;
                this.countries = countries;
                this.countries.sort();
            });
            this.route.queryParams.subscribe(params => {
                if (params.mfa && !this.model.mfaEnabled) {
                    this.onClickMfaEnable();
                }
            });
            if (this.isSub()) {
                this.controlsDisabledForSub.forEach(controlName => {
                    this.form.get(controlName).disable()
                })
            }
        });

        this.form = formBuilder.group({
            fullName: ['', Vld.compose([Vld.required, Vld.minLength(3), Vld.maxLength(255)])],
            companyName: ['', Vld.compose([Vld.required, Vld.notSpacesOnly(), Vld.minLength(3), Vld.maxLength(255)])],
            username: ['', Vld.compose([Vld.required, Vld.username(true)])],
            websiteUrl: [''],
            countryName: ['', Vld.required],
            phone: ['', Vld.compose([Vld.required, Vld.globalPhoneNumber()])],
            emailForAlert: ['', Vld.compose([Vld.email(true)])],
            emailForInvoice: ['', Vld.compose([Vld.email(true)])],
            addressCity: ['', Vld.compose([Vld.required, Vld.notSpacesOnly(), Vld.minLength(3), Vld.maxLength(100)])],
            addressStreet: ['', Vld.compose([Vld.required, Vld.notSpacesOnly(), Vld.minLength(1), Vld.maxLength(100)])],
            addressState: ['', Vld.compose([Vld.notSpacesOnly(), Vld.minLength(1), Vld.maxLength(100)])],
            addressZip: ['', Vld.compose([Vld.notSpacesOnly(), Vld.minLength(3), Vld.maxLength(20)])],
            vatNumber: ['', Vld.compose([Vld.notSpacesOnly(), Vld.minLength(3), Vld.maxLength(20)])],
            testRetriesEnabled: [''],
            testTtl: ['', UsersService.getValidatorForTestTtlMin()],
            minBalance: ['', Vld.compose([Vld.digits(), Vld.noSpace(), Vld.min(0)])],
        });

        this.ipWhitelistForm = formBuilder.group({
            ipWhitelistAddress: ['', Vld.compose([Vld.cidrAddress(true)])],
        });
        this.validationService.checkVatCountries(this.form);

        let comparePasswordValidator = () => {
            if (this.passwordForm) {
                const newPasswordFormControl = this.passwordForm.get('newPassword');
                const repeatPasswordFormControl = this.passwordForm.get('repeatNewPassword');
                if (newPasswordFormControl.value !== repeatPasswordFormControl.value) {
                    return {
                        comparePassword: true
                    };
                }
            }
            return null;
        };
        this.passwordForm = formBuilder.group({
            currentPassword: ['', Vld.compose([Vld.required])],
            newPassword: ['', Vld.compose([Vld.required, Vld.maxLength(50), Vld.password(true)]), [Vld.passwordBackend(this.service)]],
            repeatNewPassword: ['', Vld.compose([Vld.required, comparePasswordValidator])],
        });
        validationService.addFormatter('comparePassword', validationService.createTextFormatter('Passwords must match'));

        this.emailForm = formBuilder.group({
            newEmail: ['', Vld.compose([Vld.required, Vld.email(true)])]
        })
        this.mfaForm = formBuilder.group({
            mfaCode: ['', Vld.compose([
                Vld.required,
                Vld.digits(true),
                Vld.minLength(UsersService.TOTP_LENGTH),
                Vld.maxLength(UsersService.TOTP_LENGTH)
            ])]
        })

        this.validationService = validationService;
    }

    onSubmit() {
        this.loading = true;
        this.model.ipWhitelistEnabled = false;
        if (this.model.ipWhitelistAddressList != null) {
            this.model.ipWhitelistEnabled = this.model.ipWhitelistAddressList.length > 0
        }
        this.service.selfUpdate(this.model).subscribe((res) => {
            this.loading = false;
            this.notificationService.success(
                'Profile updated',
                'Account'
            );
            this.onAfterSave.emit(res);
        }, e => {
            this.loading = false;
            this.notificationService.error(
                (this.model.id ? 'Updating' : 'Creating') + ' an error',
                'Account'
            );
            this.validationService.handleRequestError(e, 'Account');
        });
    }

    hasParentAccount() {
        return !this.model || this.model.role === Role.SUB || this.model.role == Role.DEPUTY;
    }

    isAdmin() {
        return !this.model || this.model.role === Role.ADMIN;
    }

    isDeputy() {
        return !this.model || this.model.role === Role.DEPUTY;
    }

    isMain() {
        return !this.model || this.model.role === Role.MAIN;
    }

    isSub() {
        return !this.model || this.model.role === Role.SUB;
    }

    onPasswordSubmit() {
        this.loading = true;
        const model = {
            currentPassword: this.passwordModel.currentPassword,
            newPassword: this.passwordModel.newPassword
        }
        this.service.updatePassword(this.model.id, model).subscribe(() => {
            this.loading = false;
            this.notificationService.success(
                'Password changed',
                'Account'
            );
            this.passwordModalClose();
        }, e => {
            this.loading = false;
            if (e.status === 403) {
                this.notificationService.error({
                    title: 'Account',
                    message: 'Current password incorrect'
                });
            } else {
                this.notificationService.error({
                    title: 'Account',
                    message: 'Updating password an error',
                    serviceName: 'GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
            }
        });
    }

    onEmailFormSubmit(): void {
        this.loading = true;
        this.service.updateEmail(this.emailModel.newEmail).subscribe(() => {
            this.notificationService.success(
                'Email changed successfully. Please check your inbox to verify your new e-mail.',
                'Account'
            );
            this.emailModalClose();
            this.loading = false;
            this.service.logout(['/login'], ['/users']).then(url => {
                this.router.navigate(url);
            });
        }, error => {
            this.loading = false;
            this.notificationService.error({
                title: 'Account',
                message: 'Error in updating email.',
                serviceName: 'GATEWAY',
                requestMessage: error.statusText,
                requestCode: error.status
            });
            this.emailModalClose();
        });
    }

    isPasswordType(field) {
        field += 'Type';
        return this[field] === 'password';
    }

    toggleMFA(isChecked: boolean): void {
        if (!this.model.mfaEnabled) {
            this.onClickMfaEnable();
        } else {
            this.onClickMfaDisable();
        }
    }

    togglePasswordType(field) {
        field += 'Type';
        this[field] = this[field] === 'password' ? 'text' : 'password';
    }

    passwordModalOpen() {
        this.passwordModal = this.modal.alert().component(this.passwordModalTpl).open();
        this.passwordModal.result.then(() => {
            this.passwordModel.currentPassword = '';
            this.passwordModel.newPassword = '';
            this.passwordModel.repeatNewPassword = '';
        });
    }

    changeEmailModalOpen() {
        this.emailModal = this.modal.alert().component(this.emailModalTpl).open();
    }

    emailModalClose() {
        if (this.emailModal) {
            this.emailModal.close(true);
        }
    }

    passwordModalClose() {
        if (this.passwordModal) {
            this.passwordModal.close(true);
        }
    }

    shouldMinBalanceBeShown(): boolean {
        if (this.model.paymentType === PaymentType.PREPAID && (this.model.role === Role.MAIN || this.model.role == Role.DEPUTY)) {
            return true;
        }
        return false;
    }

    onClickMfaEnable() {
        this.mfaModel.mfaCode = '';
        this.mfaForm.reset();
        this.mfaLoading = true;
        this.mfaSetup = null;
        this.service.mfaSetup().pipe(
            catchError((e, caught) => {
                this.mfaLoading = false;
                if (!(e instanceof CancelError)) {
                    this.notificationService.error({
                        title: 'Account',
                        message: 'Setup 2FA',
                        serviceName: 'GATEWAY',
                        requestMessage: e.statusText,
                        requestCode: e.status
                    });
                }
                this.mfaModal.close(false);
                throw e;
            }),
        ).subscribe(setup => {
            this.mfaSetup = setup;
            this.mfaLoading = false;
        });

        this.mfaModal = this.modal.alert().isBlocking(true).component(this.mfaSetupModalTpl).open();
    }

    mfaSetupOnSubmit() {
        ConfirmedActionsService.setCurrentAction('Enable MFA');
        this.mfaLoading = true;
        this.service.mfaEnable(this.mfaModel.mfaCode).pipe(
            catchError(e => {
                this.mfaLoading = false;
                this.notificationService.error({
                    title: 'Account',
                    message: '2FA enabling an error',
                    serviceName: 'GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                throw e;
            })
        ).subscribe(() => {
            this.mfaLoading = false;
            this.model.mfaEnabled = true;
            this.notificationService.success('2FA enabled', 'Account');
        });
    }

    onMfaCodeChange(mfaCode: string) {
        if (this.mfaForm.controls.mfaCode.valid && mfaCode.length === UsersService.TOTP_LENGTH) {
            this.mfaSetupOnSubmit();
        }
    }

    onMfaCodeDisableChange(mfaCode: string) {
        if (this.mfaForm.controls.mfaCode.valid && mfaCode.length === UsersService.TOTP_LENGTH) {
            this.mfaDisableOnSubmit();
        }
    }

    mfaDisableOnSubmit() {
        this.mfaLoading = true;
        this.service.mfaDisable(this.mfaModel.mfaCode).pipe(
            catchError(e => {
                this.notificationService.error({
                    title: 'Account',
                    message: '2FA disabling an error',
                    serviceName: 'GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                this.mfaLoading = false;
                throw e;
            })
        ).subscribe(() => {
            this.model.mfaEnabled = false;
            this.notificationService.success('2FA disabled', 'Account');
            this.mfaDisableModal.close();
        });
    }

    onClickMfaDisable() {
        this.mfaModel.mfaCode = '';
        this.mfaForm.reset();
        this.mfaDisableModal = this.modal.alert().isBlocking(true).component(this.disableMfaModalTpl).open();
    }

    copyToClipboard(text: string) {
        BrowserUtils.copyToClipboard(text);
    }

    onSubmitIpWhitelistAddressForm() {
        const ipWhitelistFormControl: FormControl = this.ipWhitelistForm.get('ipWhitelistAddress') as FormControl;
        const address = ipWhitelistFormControl.value;
        if (this.ipWhitelistAddressList?.length >= 15) {
            ipWhitelistFormControl.setErrors({customError: 'You can only enter up to 15 addresses.'})
            return
        } else {
            if (ipWhitelistFormControl.errors) {
                delete ipWhitelistFormControl.errors['customError'];
            }
        }

        if (address && this.ipWhitelistForm.valid) {
            if (this.ipWhitelistAddressList.indexOf(address) == -1) {
                this.ipWhitelistAddressList.push(address);
                this.updateIpWhitelistAddressList();
                ipWhitelistFormControl.patchValue('');
                ipWhitelistFormControl.reset();
            } else {
                ipWhitelistFormControl.setErrors({customError: 'The address you entered is already added.'})
            }
        }
    }

    onRemoveIpWhitelistAddress(address) {
        const ipWhitelistFormControl: FormControl = this.ipWhitelistForm.get('ipWhitelistAddress') as FormControl;
        this.ipWhitelistAddressList = this.ipWhitelistAddressList.filter(_ => _ !== address);
        this.updateIpWhitelistAddressList();
        ipWhitelistFormControl.reset();
    }

    updateIpWhitelistAddressList() {
        let str = this.ipWhitelistAddressList.join(',');
        this.model.ipWhitelistAddressList = str;
    }

    showDevicesModal() {
        this.modal.alert().dialogClass('modal-dialog large-modal').component(this.devicesModalTpl).open();
    }
}