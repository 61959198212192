import { FormGroup, FormControl } from '@angular/forms';
import { NotificationService } from './notification.service';
import { AsyncValidatorFn, ValidationErrors, ValidatorFn, AbstractControl, Validators as Vld } from "@angular/forms";
import { Injectable } from "@angular/core";
import { SuppliersService } from './suppliers.service';
import { UsersService } from "./users.service";
import { Observable, of, switchMap, delay } from "rxjs";
import { AccountFormComponent } from "../../users/account-form/account-form.component";

declare type FormatterFn = (params: any) => string[]
declare type Formatters = {[key: string]: FormatterFn;}

@Injectable()
export class ValidationService {

    vatCountries = new Set([
        "Austria", "Belgium", "Bulgaria", "Croatia", "Cyprus", "Czech Republic", "Denmark", "Estonia", "Finland",
        "France", "Germany", "United Kingdom", "Greece", "Hungary", "Ireland", "Italy", "Latvia", "Lithuania",
        "Luxembourg", "Malta", "Netherlands", "Poland", "Portugal", "Romania", "Slovakia", "Slovenia", "Spain", "Sweden"
    ])
    formatters: Formatters = {};

    constructor(protected notificationService: NotificationService) {
        let textFormatters = {
            required: 'Cannot be blank.',
            notSpacesOnly: 'Cannot be only spaces.',
            minlength: 'Should contain at least %requiredLength% characters.',
            maxlength: 'Should contain at most %requiredLength% characters.',
            emailDomain: 'This email address cannot be used.',
            min: 'Must be no less than %min%.',
            max: 'Must be no greater than %max%.',
            pattern: 'Is invalid.',
            ip: 'Must be a valid IP address.',
            digits: 'Must be an integer.',
            digitsAndDot: 'Must only contain digits and dot',
            digitsAndSpaces: 'Must only contain digits and spaces.',
            digitsAndCommas: 'Prefix must be %min% to %max% digits long, containing only digits and commas.',
            telqId: 'Must only contain uppercase and lowercase letters (A-Z or a-z)',
            integer: 'Must be an integer.',
            email: 'Is not a valid email address.',
            url: 'Is not a valid URL.',
            price: 'Value does not match the format 0.00',
            password: "Use 8 or more characters with a mix of letters, numbers & special characters like !@#$%^&*()_+\\-=[]{};':\"|,.<>/?",
            gsm7: 'Must contain only gsm7 characters (https://en.wikipedia.org/wiki/GSM_03.38)',
            hex: 'Must be a hexadecimal string. Ex.: abcd',
            domainOrIp: 'Must be a domain or IP address',
            restrictSpace: 'Cannot contain space',
            globalPhoneNumber: 'Please enter a valid phone number.',
            username: 'Use 4 to 20 characters with a mix of letters, numbers & special characters like _ . - ',
            hourFormat24OrNumber: 'Please enter a time. Eg, 10:34',
            smppTimeStamp: 'Should be in format YYMMDDhhmmsstnnp.',
            routeTypes: 'Only these route types are allowed: Direct, Wholesale, Premium, HQ, SIM, SS7 and Other.',
            otherRouteType: 'Invalid route type value.',
            cidrAddress: 'Must be correct ip or network address',
            tillHourInvalid: 'Time interval has to be at least 10 minutes.'
        };
        for (let type in textFormatters) {
            this.addFormatter(type, this.createTextFormatter(textFormatters[type]));
        }
        this.addFormatter('custom', params => {
            if (typeof params.message === 'undefined' || !params.message) {
                return [];
            }
            if (typeof params.params === 'undefined') {
                params.params = {};
            }
            let text = params.message;
            for (let i in params.params) {
                text = text.replace(new RegExp(`%${i}%`, 'g'), params.params[i]);
            }
            return [text];
        });
        this.addFormatter('passwordBackend', params => {
            if (Array.isArray(params)) {
                return params;
            }
            return [];
        });
        this.notificationService = notificationService;
    }

    getTextMessage(type: string, params: any): string[] {
        return this.getFormatter(type).apply(null, [params]);
    }

    addFormatter(type: string, formatter: FormatterFn) {
        this.formatters[type] = formatter;
    }

    getFormatter(type: string): FormatterFn {
        if (typeof this.formatters[type] === 'undefined') {
            throw new Error(`Formatter ${type} undefined`);
        }
        return this.formatters[type];
    }

    createTextFormatter(text: string): FormatterFn {
        return (params: any): string[] => {
            let t = text;
            for (let i in params) {
                t = t.replace(new RegExp(`%${i}%`, 'g'), params[i]);
            }
            return [t];
        };
    }

    handleRequestError(error, notifyTitle = 'Validation') {
        try {
            if (error.statusText) {
                this.notificationService.error(error.statusText, notifyTitle);
            } else {
                this.notificationService.error(typeof error.message !== 'undefined' ? error.message : 'Request error', notifyTitle);
            }
        } catch (error) {
            this.notificationService.error(typeof error.message !== 'undefined' ? error.message : 'Request error', notifyTitle);
        }
    }

    checkRouteTypeValidation(form: FormGroup) {
        form.get('routeType').valueChanges.subscribe(routeTypeValue => {
            const customRouteTypeControl = form.get('customRouteType');
            if (routeTypeValue === 'Custom') {
                customRouteTypeControl.setValidators([Vld.required, Vld.minLength(1), Vld.maxLength(255)]);
            } else {
                customRouteTypeControl.clearValidators();
            }
            customRouteTypeControl.updateValueAndValidity();
        });
    }

    checkVatCountries(form: FormGroup) {
        form.get('countryName').valueChanges.subscribe(countryNameValue => {
            const vatNumberControl = form.get('vatNumber');
            if (this.vatCountries.has(countryNameValue)) {
                vatNumberControl.addValidators(Vld.required);
            } else {
                vatNumberControl.removeValidators(Vld.required);
            }
            vatNumberControl.updateValueAndValidity();
        });
    }

    validateFile(file: File, control: FormControl, maxSize: number = 0, allowedExtensions: string[] = []) {
        let errors = {};
        if (file) {
            if (maxSize && file.size > maxSize) {
                errors['custom'] = {
                    message: 'Max file size ' + ((maxSize / 1024 / 1024).toFixed(1)) + ' mb'
                };
            } else if (allowedExtensions.length) {
                let name = file.name.split('.');
                let ext = name[name.length - 1].toLowerCase();
                if (allowedExtensions.indexOf(ext) === -1) {
                    errors['custom'] = {
                        message: 'Allowed file types: ' + allowedExtensions.join(', ')
                    };
                }
            }
        }

        control.setErrors(errors);

        return Object.keys(errors).length === 0;
    }

    static getInvalidControls(form: FormGroup): { name: string; control: AbstractControl }[] {
        return Object.keys(form.controls)
            .map(_ => { return { name: _, control: form.controls[_] } })
            .filter(_ => !_.control.valid);
    }
}

export class Validators {

    static readonly gsm7Reg = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&amp;'()*+,\\-./:;&lt;=&gt;?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");

    static readonly ipv6 = new RegExp("^(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}(?:\/([0-9]{1,2}|1[01][0-9]|12[0-8]))?$|^(?:[0-9a-fA-F]{1,4}:){0,7}[0-9a-fA-F]{1,4}::(?:\/([0-9]{1,2}|1[01][0-9]|12[0-8]))?$|^(?:[0-9a-fA-F]{1,4}:){1,7}::(?:\/([0-9]{1,2}|1[01][0-9]|12[0-8]))?$|^::(?:\/([0-9]{1,2}|1[01][0-9]|12[0-8]))?$");

    static readonly ipv4 = new RegExp("^((25[0-5]|2[0-4]\\d|[0-1]?\\d?\\d)(\\.(25[0-5]|2[0-4]\\d|[0-1]?\\d?\\d)){3})(?:/(3[0-2]|[1-2]?\\d))?$");

    static readonly domain = new RegExp("^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\\.[A-Za-z0-9-]{1,63})*\\.[A-Za-z]{2,}$");

    static readonly spacesOnly = new RegExp("^\\s+$");

    static required(control: AbstractControl): ValidationErrors | null {
        return Vld.required(control);
    };

    static minLength(minLength: number): ValidatorFn {
        return Vld.minLength(minLength);
    };

    static maxLength(maxLength: number): ValidatorFn {
        return Vld.maxLength(maxLength);
    };

    static compose(validators: (ValidatorFn | null | undefined)[]): ValidatorFn | null {
        return Vld.compose(validators)
    };

    static composeAsync(validators: (AsyncValidatorFn | null)[]): AsyncValidatorFn | null {
        return Vld.composeAsync(validators);
    }

    static digits(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value;
            return ((skipEmpty && !v) || /^\d+$/.test(v)) ? null : { digits: true };
        };
    }

    static digitsAndDot(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value;
            return ((skipEmpty && !v) || /^[0-9]*\.?[0-9]*$/.test(v)) ? null : { digitsAndDot: true };
        };
    }

    static digitsAndSpaces(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value;
            return ((skipEmpty && !v) || /^[\d\s]+$/.test(v)) ? null : { digitsAndSpaces: true };
        };
    }

    static digitsAndCommas(skipEmpty: boolean = false, min: number = 1, max: number = 100): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value;
            const regex = new RegExp(`^\\d{${min},${max}}(,\\d{${min},${max}})*$`);
            return ((skipEmpty && !v) || regex.test(v)) ? null : { digitsAndCommas: { min, max } };
        };
    }    

    static telqId(skipEmpty: boolean = false, skipSpaces = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value;
            if (skipSpaces) {
                v = v.replace(' ', '');
            }
            return ((skipEmpty && !v) || /^[0-9]*\.?[0-9]*$/.test(v)) ? null : { telqId: true };
        };
    }

    static gsm7(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value;
            return ((skipEmpty && !v) || Validators.gsm7Reg.test(v)) ? null : { gsm7: true };
        };
    }

    static noSpace(): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value + '';
            if (v && v.includes(' ')) {
                return { restrictSpace: true };
            }
            return null;
        }
    }

    static notSpacesOnly(): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            return Validators.spacesOnly.test(control.value) ? { notSpacesOnly: true } : null;
        }
    }

    static email(skipEmpty?: boolean): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            let v: string = control.value;
            let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return ((skipEmpty && !v) || pattern.test(v)) ? null : { email: true };
        };
    }

    static password(skipEmpty: boolean = false): ValidatorFn {
        return Vld.minLength(8);
        /*return (control: FormControl): ValidationErrors => {
            let v = control.value;
            let requirements = [
                /.{8,}/, //min length: 8 symbols
                /([a-zA-Z])/,
                /[0-9]/,
                /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
            ];

            return ((skipEmpty && !v) || requirements.filter(_ => _.test(v)).length >= requirements.length) ? null : { password: true };
        };*/
    }

    static price(digits: number = 2, skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = typeof control.value === 'number' ? (control.value + '') : control.value;
            if (skipEmpty && !v) {
                return null;
            }
            let parts = v.split('.');
            if (parts.length !== 2) {
                return null;
            }
            return parts[1].length <= digits ? null : { price: true };
        };
    }

    static equal(val: any, skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value;
            return ((skipEmpty && !v) || val === v) ? null : { equal: { val: val } };
        }
    }

    static url(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
            let v: string = control.value;
            return ((skipEmpty && !v) || pattern.test(v)) ? null : { 'url': true };
        }
    }

    static ip(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
            let v: string = control.value;
            return ((skipEmpty && !v) || pattern.test(v)) ? null : { ip: true };
        }
    }

    static username(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let pattern = /^[a-zA-Z0-9_.-]{4,20}$/;
            let v: string = control.value;
            return ((skipEmpty && !v) || pattern.test(v)) ? null : { username: true };
        }
    }

    static min(min: number, skipEmpty?: boolean): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: number = control.value;
            return ((skipEmpty && !v) || v >= min) ? null : { min: { min } };
        }
    }

    static max(max: number, skipEmpty?: boolean): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: number = control.value;
            return ((skipEmpty && !v) || v <= max) ? null : { max: { max } };
        }
    }

    static senderId(maxAlpha: number, maxNumber: number, skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            const enteredValue: string = String(control.value);
            let value: string = String(control.value);
            let numbers = new RegExp('^[0-9]+$');
            let max = numbers.test(value) ? maxNumber : maxAlpha;
            if (skipEmpty && !value.length) {
                return null;
            }
            if (value.startsWith("+")) {
                value = value.substring(1);
                max = numbers.test(value) ? maxNumber : maxAlpha;
            }
            return enteredValue.length <= max ? null : { 'max': { max: max, filter: true } };
        }
    }

    static mixedMaxLen(maxAlpha: number, maxNumber: number, skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            if (!control) {
                return null;
            }
            let v: number = control.value.length;
            if (skipEmpty && !v) {
                return null;
            }
            let numbers = new RegExp('^[0-9]+$');
            let max = numbers.test(control.value) ? maxNumber : maxAlpha;

            return v <= max ? null : { 'max': { required: max, filter: true } };
        }
    }

    static integer(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: number = control.value;
            return ((skipEmpty && !v) || (!isNaN(v) && v == v)) ? null : { integer: true };
        }
    }

    static hex(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let pattern = /^[0-9a-fA-F]+$/;
            let v: string = control.value;
            return ((skipEmpty && !v) || pattern.test(v)) ? null : { 'hex': true };
        }
    }

    static domainOrIp(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value;
            return ((skipEmpty && !v) || (Validators.domain.test(v) || Validators.ipv4.test(v) || Validators.ipv6.test(v))) ? null : { 'domainOrIp': true };
        }
    }

    static cidrAddress(skipEmpty: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value;
            return ((skipEmpty && !v) || Validators.ipv4.test(v) || Validators.ipv6.test(v)) ? null : { 'cidrAddress': true };
        }
    }

    static globalPhoneNumber(isInternational: boolean = false): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            // International pattern ...
            let pattern = /^[+]?[0-9]{3,20}$/;
            if (isInternational) {
                pattern = /^(?:(?:\+|0{1,3})?(?:[1-9]\d{0,14}|\d))$/;
            }
            let v: string = control.value + '';
            if (v.length === 0) {
                return null;
            }
            return pattern.test(v) ? null : { 'globalPhoneNumber': true };
        }
    }

    static hourFormat24(): ValidatorFn {
        return (control: FormControl): ValidationErrors => {
            let pattern24Hour = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
            let v: string = control.value + '';
            if (v.length === 0) {
                return null;
            }
            if (pattern24Hour.test(v)) {
                return null;
            }
            return { 'hourFormat24OrNumber': true };
        }
    }

    static routeTypes(): ValidatorFn {
        const routeTypes = SuppliersService.ROUTE_TYPES.map(_ => _.toUpperCase())
        return (control: FormControl): ValidationErrors => {
            let v: string = control.value?.toUpperCase();
            if (routeTypes.indexOf(v) === -1) {
                return { 'routeTypes': true };
            }
            return null;
        }
    }

    static passwordBackend(userService: UsersService, delayMs = 300): AsyncValidatorFn {
        return (control: FormControl): Observable<ValidationErrors | null> => {
            if (!control.value) { return of(null); }
            return of(control.value).pipe(
                delay(delayMs),
                switchMap(val => userService.checkPasswordValidity(val))
            )
        }
    }
}