
import { Clipboard } from '@angular/cdk/clipboard';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { CellClickedEvent, ColDef, ColumnMovedEvent, ColumnResizedEvent, ColumnVisibleEvent, GetContextMenuItemsParams, GridReadyEvent, ICellRendererParams, MenuItemDef, RowSelectedEvent } from 'ag-grid-community';
import { catchError, debounceTime, of, tap } from 'rxjs';
import { PhoneReportsWidgetComponent } from "../../../phone-reports/phone-reports-widget/phone-reports-widget.component";
import { AGTableBase } from '../../../shared/components/table/ag-table-base';
import { TestDetails, TestResult, TestResultsCollection } from '../../../shared/models/test-result.model';
import { AuthUser, Role } from '../../../shared/models/user.model';
import { BrowserUtils } from "../../../shared/services/browser-utils";
import { LiveNumberTestingService } from '../../../shared/services/live-number-testing.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { AllRequestParams, ManualNumberTestingService } from '../../../shared/services/manual-number-testing.service';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from '../../../shared/services/notification.service';
import { UsersService } from '../../../shared/services/users.service';
import { MntActionsComponent } from './mnt-actions/mnt-actions.component';
import { CustomUtils } from '../../../shared/services/custom-utils';
declare var moment: any;


@Component({
    selector: 'app-mnt-results-table',
    templateUrl: 'mnt-results-table.component.html',
    styleUrls: ['mnt-results-table.component.scss'],
})

export class MntResultsTableComponent extends AGTableBase implements OnInit, OnDestroy {

    @ViewChild(PhoneReportsWidgetComponent, { static: false }) phonenumberReportWidget: PhoneReportsWidgetComponent;
    @ViewChild('exportModalTpl', { read: TemplateRef, static: false }) exportModalTpl: any;
    @ViewChild('textModalTpl', { read: TemplateRef, static: false }) textModalTpl: any;
    @ViewChild('copyModalTpl', { read: TemplateRef, static: false }) copyModalTpl: any;
    @ViewChild('smscModalTpl', { read: TemplateRef, static: false }) smscModalTpl: any;
    @ViewChild('commentModalTpl', { read: TemplateRef, static: false }) commentModalTpl: any;

    @Input() compact: boolean = false;
    @Input() batchRepeatRows: TestResult[] = [];

    @Output() actions = new EventEmitter<unknown>();
    @Output() onAfterDelete = new EventEmitter<unknown>();
    @Output() onRepeat = new EventEmitter<unknown>();
    @Output() changeBatch = new EventEmitter<TestResult[]>();

    isAdmin = false;
    user: AuthUser;
    loading: boolean = false;

    commentModel;
    commentModal: DialogRef;

    batchSelectedIds: Set<number> = new Set();
    currentSection: string = '';
    intervalTimer: any;

    testCaseDetails: TestDetails[] = [];
    smscData = {
        smsc: {
            phone: '',
            country: '',
            mcc: null,
            mnc: null,
            provider: null
        },
        test: {
            phone: '',
            country: '',
        },
        country: '',
        isError: false
    };

    searchParams: any;
    storageContext = 'mtc-results';
    taskId: any;
    textModalHtml: string;

    // ng grid
    columnDefs: ColDef[] = [
        {
            headerName: '', field: 'batch', checkboxSelection: true,
            maxWidth: 40, headerCheckboxSelection: true, pinned: 'left',
            lockPinned: true, lockPosition: 'left', lockVisible: true,
            suppressColumnsToolPanel: true, suppressMenu: true, suppressMovable: true
        },
        {
            headerName: 'ID', field: 'id',
            hide: true, flex: 0.5,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.id}</div>`;
            }
        },
        {
            headerName: 'Date', field: 'createdAtFormat',
            minWidth: 90, maxWidth: 90,
            flex: 0.6,
            headerTooltip: 'Date and time when the test was triggered, in the browser’s time zone',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatDate(_);
            },
        },
        {
            headerName: 'UTC Date', field: 'utcTimestamp', minWidth: 90, maxWidth: 90,
            flex: 0.6, headerTooltip: 'UTC date for test created date.', hide: true,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return CustomUtils.formatDateIntoUTC(_.createdAt)
            },
        },
        {
            headerName: 'Test ID Text', field: 'telqId',
            headerTooltip: 'The string of random characters which needs to be included in the message body of the test SMS',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.telqId}</div>`;
            }
        },
        {
            headerName: 'Country(MCC)/Network(MNC)', field: 'destinationDto',
            headerTooltip: 'The destination to which the test was sent',
            flex: 1.5,
            cellRenderer: (params: ICellRendererParams) => {
                return `<div class="two-lines">${params.value ? this.formatCountry(params.value) : ''}</div>`;
            }
        },
        {
            headerName: 'Receipt status', field: 'testStatus',
            headerTooltip: 'Receipt status of the test, indicating whether the test was delivered to our test number',
            cellClass: 'd-flex align-items-center justify-content-center',
            minWidth: 90, maxWidth: 90, flex: 0.6,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return this.formatReceiptStatus(_);
            }
        },
        {
            headerName: 'Sender ID', field: 'telqId',
            headerTooltip: 'Sender ID of the delivered SMS (if available)',
            minWidth: 130, flex: 1,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.senderDelivered ? _.senderDelivered : ''}</div>`;
            }
        },
        {
            headerName: 'Text', field: 'textDelivered',
            headerTooltip: 'Text of the delivered SMS (if available)',
            flex: 1.5,
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return this.formatText(row);
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),
        },
        {
            headerName: 'Phone/SMSC', field: 'phonenumber',
            headerTooltip: 'Test number to which the test SMS is to be sent',
            flex: 1, minWidth: 130,
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return this.formatPhoneSmsc(row);
            },
            onCellClicked: (event: CellClickedEvent) => this.onCellClick(event),
        },
        {
            headerName: 'Receipt delay', field: 'receiptDelay',
            headerTooltip: 'The time interval between the creation of a test and the delivery of a test to our server',
            minWidth: 90, maxWidth: 90, flex: 0.6,
            cellRenderer: (params: ICellRendererParams) => {
                const row = params.data;
                return `<div class="two-lines">${this.formatDelay(row)}</div>`;
            }
        },
        {
            headerName: 'User', field: 'user',
            flex: 0.5,
            headerTooltip: 'User that triggered the test',
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return `<div class="one-line">${_.user ? (_.user.username ? _.user.username : _.user.email) : 'N/A'}<div>`;
            }
        },
        {
            headerName: 'Price', field: 'price', flex: 0.4,
            cellRenderer: (params: ICellRendererParams) => {
                const _ = params.data;
                return _.price > 0 ? _.price / 1000 : 0;
            }
        },
        {
            headerName: 'Actions', field: 'actions', maxWidth: 100, minWidth: 100,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            tooltipField: 'actions',
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: MntActionsComponent,
        }
    ];

    rowData!: TestResult[];
    // ng grid

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public service: ManualNumberTestingService,
        public liveService: LiveNumberTestingService,
        public userService: UsersService,
        public localStorage: LocalStorageService,
        private clipboard: Clipboard,
        public modal: ModalService
    ) {
        super();
        userService.getAuthUser().then(user => {
            this.isAdmin = user.role === Role.ADMIN;
            this.user = user;
            this.init();
        }).catch(e => { });
    }

    init() {
        let hideColumns = [];
        if (!this.isAdmin && !this.user.impersonated) {
            hideColumns.push('price');
        }

        if (this.user.role === 'subaccount' && !this.user.showAllResults) {
            hideColumns.push('user');
        }
        if (this.compact) {
            hideColumns.push('');
            hideColumns.push('ID');
            hideColumns.push('User');
            hideColumns.push('Price');
        }
        this.columnDefs = this.columnDefs.filter(c => !hideColumns.includes(c.headerName));
        this.paginationPageSize = this.localStorage.get(`mnt_results_table_component_size_${this.user.id}`) || 20;
        const savedUserSearchDetails = this.localStorage.get(`mnt_user_search_${this.user.id}_search`);
        if (savedUserSearchDetails) {
            this.searchParams = savedUserSearchDetails;
        }
        this.intervalTimer = setInterval(() => {
            if (this.loading) {
                return;
            }
            this.update(false);
        }, 1000 * 20);
    }

    ngOnInit() {
        this.actionSubscription = this.service.action$.subscribe(
            action => this.onAction(action)
        );
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.localStorage.set(`mnt_table_state_${this.user.id}`, this.gridApi.getColumnState());
        });
    }

    formatDate(_: TestResult) {
        return `<div class="two-lines"><span title="${_.taskId ? 'Scheduled' : ''}" class="${_.taskId ? 'text-primary' : ''}">${_.createdAtFormat[1]} <br>${_.createdAtFormat[0]}</span></div>`
    }

    formatCountry(c, icon = true) {
        return this.service.formatDestination(c, icon);
    }

    formatReceiptStatus(_: TestResult) {
        const statusDef = {
            wait: '<span class="icon-wait spin" title="Wait"></span>',
            positive: '<span class="icon-positive text-sucess-s2" title="Positive"></span>',
            sender_replaced: '<span class="icon-attention text-accent-a2" title="Sender ID replaced"></span>',
            text_replaced: '<span class="icon-attention text-accent-a2" title="Text replaced"></span>',
            test_number_offline: '<span class="icon-negative text-accent-a2" title="Test number offline"></span>',
            test_number_not_available: '<span class="icon-test-number-not-available text-accent-a2" title="Test number not available"></span>',
            network_offline: '<span class="icon-network-offline text-error-r3" title="Network offline"></span>',
            not_delivered: '<span class="icon-negative text-error-r3" title="Negative"></span>',
            def: '<span class="icon-negative text-error-r3" title="Negative"></span>',
            internal_error: '<span class="icon-bug-red p-0_2" title="Internal error happend during test execution, you won\'t be charged. Try to re-execute the test."></span>',
            rcs_positive: '<span class="icon-rcs-positive" title="RCS Positive"></span>',
            rcs_replaced: '<span class="icon-rcs-text-replaced" title="RCS Sender ID/Text replaced"></span>',
        };
        let key = typeof statusDef[_.testStatus.toLowerCase()] !== 'undefined' ? _.testStatus.toLowerCase() : 'def';
        if (key === 'positive' && _.channel === 'rcs') {
            key = 'rcs_positive';
        }
        if ((key === 'sender_replaced' || key === 'text_replaced') && _.channel === 'rcs') {
            key = 'rcs_replaced';
        }
        return `<div class="h-100 fs-2">${statusDef[key]}</div>`;
    }

    formatText(row: TestResult): string {
        if (!row.textDelivered) {
            return BrowserUtils.escapeHtml(row.textDelivered);
        }
        let text = BrowserUtils.escapeHtml(String(row.textDelivered));
        return `<div class="one-line clickable"><span>${text}</span></div>`;
    }

    formatPhoneSmsc(row: TestResult): string {
        let result = [];
        if (row.destinationDto.phone) {
            result.push(`<span>${row.destinationDto.phone}</span>`);
        }
        let hlr = row.smscInfoDto;
        let onlyNumber = hlr.phone && !hlr.countryName;
        if (hlr !== null && ((hlr.countryName && hlr.countryCode) || onlyNumber)) {
            let className = ((hlr.countryName !== row.destinationDto.countryName) || onlyNumber) ? 'text-error-r3' : 'text-sucess-s2';
            let title = onlyNumber ? hlr.phone : `${hlr.countryName} (${hlr.phone})`;
            let content = onlyNumber ? '?' : hlr.countryCode;
            result.push(`<span class="${className}" title="${title})">${content}</span>`);
        }
        return '<div class="two-lines clickable">' + result.join('<span class="delimiter">/</span>') + '</div>';
    }

    formatDelay(row: TestResult) {
        let delay = row.receiptDelay;
        let toSec = (del) => {
            let hours = Math.floor(del / 3600);
            let minutes = Math.floor((del - (hours * 3600)) / 60);
            let seconds = del - (hours * 3600) - (minutes * 60);

            let parts = [];
            if (hours) {
                parts.push(hours + 'h');
            }
            if (minutes) {
                parts.push(minutes + 'm');
            }
            if (seconds || !parts.length) {
                parts.push(seconds + 's');
            }

            let text = parts.join(' ');
            return del >= 300 ? `<span class="text-accent-a2">${text}</span>` : text;
        };

        if (typeof delay === 'number') {
            return toSec(delay);
        }
        return '';
    }

    changeSize($event, size) {
        this.paginationPageSize = size;
        this.localStorage.set(`mnt_results_table_component_size_${this.user.id}`, size);
        this.update();
    }

    handleBatchRepeat() {
        this.loading = true;
        this.rowData = this.batchRepeatRows;
        this.gridApi.updateGridOptions({ rowData: this.rowData });
        this.totalRowsCount = this.batchRepeatRows.length;
        this.loading = false;
    }

    update(loading = true) {
        if (!this.user.enabled || this.batchRepeatRows.length > 0) {
            if (this.batchRepeatRows.length > 0) {
                this.handleBatchRepeat();
            }
            return;
        }

        this.loading = loading;
        const params = new AllRequestParams();
        params.size = this.paginationPageSize;
        params.page = this.currentPageNumber - 1;

        if (this.taskId) {
            this.searchParams = { taskIds: [this.taskId] };
        }
        let operation = null;
        if (this.searchParams) {
            operation = this.service.searchResults(params, this.searchParams);
        } else {
            operation = this.service.results(params);
        }

        operation.pipe(
            tap(_ => this.loading = false),
            catchError(error => {
                this.handleError(error);
                return of(null);
            })
        ).subscribe(data => {
            if (data) {
                this.handleSuccess(data);
            }
        });
    }

    handleSuccess(data: TestResultsCollection) {
        this.rowData = data.content.map(this.formatCreatedAt);
        this.totalRowsCount = data.totalElements;
        setTimeout(() => this.selectBatchNodes(), 0);
    }

    handleError(error) {
        this.loading = false;
        this.rowData = [];
        this.totalRowsCount = 0;
        this.notificationService.error({
            title: 'Manual testing',
            message: 'An error occurred while loading the results',
            serviceName: 'MTC',
            requestMessage: error.statusText,
            requestCode: error.status,
            ts: error.timestamp || null
        });
    }

    formatCreatedAt(item) {
        const time = typeof item.createdAt === 'number' ? item.createdAt * 1000 : Date.parse(item.createdAt);
        item.createdAtFormat = moment(time).format('DD/MM/YY HH:mm:ss').split(" ");
        return item;
    }

    selectBatchNodes() {
        this.gridApi.forEachNode(node => {
            if (this.batchSelectedIds.has(node.data.id)) {
                node.setSelected(true);
            }
        });
    }

    onAction(event) {
        this.actions.emit(event);
        if (event.name === 'copy') {
            this.testCaseDetails = [];
            this.modal.alert().component(this.copyModalTpl).open().onDestroy.subscribe(() => { });
            this.service.getResultDetailsText(event.row).subscribe(text => {
                this.testCaseDetails = this.getFormattedData(text);
                this.currentSection = this.testCaseDetails[0].id;
            }, e => {
                console.error(e);
            });
        }

        if (event.name === 'addPhonenumberReport') {
            this.phonenumberReportWidget.open(event.row.destinationDto.phone).then(d => {
                d.onDestroy.subscribe(() => { });
            });
        }

        if (event.name === 'showModem') {
            this.router.navigate(['/appscripts/users'], { queryParams: { phonenumber: event.row.phonenumber } })
        }

        if (event.name === 'repeat') {
            this.onRepeat.emit(event.row);
        }

        if (event.name === 'comment') {
            this.commentModel = {
                text: event.row.comment ? event.row.comment.text : '',
                changedAt: event.row.comment ? new Date(event.row.comment.changedAt) : null,
                changedUser: event.row.comment ? event.row.comment.changedUser : null,
                editable: !event.row.comment
            };
            this.commentModel = this.modal.alert().component(this.commentModalTpl).open();
            this.commentModal.onDestroy.subscribe(() => { });
        }
    }

    onAfterSaveComment() {
        if (this.commentModal) {
            this.commentModal.close();
        }
    }

    onCellClick(event: CellClickedEvent) {
        if (event.colDef.field === 'textDelivered') {
            this.textModalHtml = BrowserUtils.sanitizeHTML(event.data.textDelivered);
            if (event.data.telqId) {
                const telqIdContent = `<span title="Test ID Text" class="text-accent-a6">${event.data.telqId}</span>`;
                this.textModalHtml = this.textModalHtml.replace(event.data.telqId, telqIdContent);
            }
            if (this.textModalHtml.trim().length > 0) {
                this.modal.alert().component(this.textModalTpl).open().onDestroy.subscribe(() => { });
            }
        }
        if (event.colDef.field === 'phonenumber' && event.data.smscInfoDto) {
            let hlr = event.data.smscInfoDto;
            if (!hlr.phone) {
                return;
            }
            this.smscData.test.phone = event.data.destinationDto.phone;
            this.smscData.test.country = event.data.destinationDto.countryName;
            this.smscData.smsc.phone = hlr.phone;
            this.smscData.smsc.country = hlr.countryName;
            this.smscData.isError = hlr.countryName !== event.data.destinationDto.countryName;
            this.smscData.smsc.mnc = hlr.mnc ? hlr.mnc : null;
            this.smscData.smsc.mcc = hlr.mcc ? hlr.mcc : null;
            this.smscData.smsc.provider = hlr.providerName ? hlr.providerName : null;

            this.modal.alert().component(this.smscModalTpl).open().onDestroy.subscribe(() => { });
        }
    }

    getFormattedData(details: TestDetails[]): TestDetails[] {
        details.forEach(section => {
            section.data = section.data.map(detail => {
                const [first, ...rest] = detail.split(':');
                return [first, rest.join(':')];
            });
        });
        return details;
    }

    onSectionChange(sectionId: string) {
        this.currentSection = sectionId ? sectionId : this.testCaseDetails[0].id;
    }

    scrollTo(section) {
        document.querySelector('#' + section)
            .scrollIntoView();
    }

    // ng grid

    onRowSelected(event: RowSelectedEvent) {
        const selectedRows = this.gridApi.getSelectedRows();
        this.batchSelectedIds = new Set(selectedRows.map(row => row.id));
        this.changeBatch.emit(selectedRows);
    }

    resetBatch() {
        this.batchSelectedIds.clear();
        this.gridApi.deselectAll();
    }

    getContextMenuItems = (
        params: GetContextMenuItemsParams
    ): (MenuItemDef)[] => {
        const currentRowClicked = params.node.data;
        const result: (MenuItemDef)[] = [
            {
                name: 'Test Case Details ',
                icon: '<span class="icon-tests-settings fs-4"></span>',
                action: () => {
                    this.service.announceAction({ name: 'copy', row: currentRowClicked, column: 'action' })
                },
            },
            {
                name: 'Phone number report',
                icon: '<span class="icon-bug ms-0_2 fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'addPhonenumberReport', row: currentRowClicked, column: 'action' })
                },
            },
            {
                name: 'Show modem',
                icon: '<span class="icon-mo-testing fs-4"></span>',
                action: () => {
                    this.onAction({ name: 'showModem', row: currentRowClicked, column: 'action' })
                },
            }, ,
            {
                name: 'Copy',
                icon: '<span class="icon-copy fs-4"></span>',
                action: () => {
                    const renderedValue = this.getRenderedCellValue(params);
                    this.clipboard.copy(renderedValue ? renderedValue : currentRowClicked.id);
                },
            }
        ];
        if (this.isAdmin) {
            return result;
        }
        return result.filter(item => item.name !== 'Show modem');
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        const columnState = this.localStorage.get(`mnt_table_state_${this.user.id}`);
        this.update();
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }
    // ng grid

    ngOnDestroy() {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
        }
        this.actionSubscription.unsubscribe();
        this.columnChange$.unsubscribe();
    }
}
