import {
    Component,
    HostListener,
    ViewChild,
    Input,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { StatisticsService } from '../../shared/services/statistics.service';
import * as Highcharts from 'highcharts';
import { IService, TestCountServiceGraph } from "../../shared/models/statistics.model";
import { LocalStorageService } from "../../shared/services/localStorage.service";
import { Subscription } from "rxjs";
import { BasicUser } from '../../shared/models/user.model';

@Component({
    selector: 'app-dashboard-test-count-service-widget',
    templateUrl: './dashboard-test-count-service-widget.component.html',
    styleUrls: ['dashboard-test-count-service-widget.component.scss', '../shared.scss']
})

export class DashboardTestCountServiceWidgetComponent implements AfterViewInit, OnChanges {

    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild('chartContainer', { static: false }) chartContainer;

    chart: Highcharts.Chart;
    chartOptions: Highcharts.Options = {
        chart: { height: 190, style: { fontFamily: StatisticsService.chartFontFamily } },
        title: { text: 'Test sent', x: -20, style: { display: 'none' } },
        colors: StatisticsService.colors,
        xAxis: {
            type: 'datetime',
            gridLineWidth: 1,
            tickWidth: 0,
            allowDecimals: false,
            crosshair: StatisticsService.chartCrosshair
        },
        yAxis: [{ title: { text: null } }],
        tooltip: StatisticsService.chartTooltip,
        legend: { align: 'center', borderWidth: 0 },
        series: [],
        credits: { enabled: false },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.3,
            }
        }
    };

    chartData: TestCountServiceGraph;
    chartItems: 'ui' | 'api' = 'ui';

    @Input() from: string;
    @Input() to: string;
    @Input() users: BasicUser[];

    loading = true;

    private request: Subscription;

    @HostListener('window:resize') onResize() { this.resize(); }
    resize() {
        if (!this.chartContainer || !this.chart) { return; }
        this.chart.setSize(this.chartContainer.nativeElement.offsetWidth, <number>this.chartOptions.chart.height);
    }

    constructor(
        public statistics: StatisticsService,
        public notificationService: NotificationService,
        private localStorage: LocalStorageService
    ) {
        const chartItems = localStorage.get('dashboard_services_items', this.chartItems);
        if (chartItems === 'ui' || chartItems === 'api') {
            this.chartItems = chartItems;
        }
    }

    ngAfterViewInit() {
        this.resize();
        this.update();
    }

    update() {
        this.loading = true;
        if (this.request && !this.request.closed) { this.request.unsubscribe(); }
        this.request = this.statistics.services(this.from, this.to, this.users).subscribe({
            next: data => {
                this.loading = false;
                this.chartData = data;
                if (this.chart) {
                    this.chart.hideLoading();
                    this.updateChart();
                }
            },
            error: error => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Dashboard',
                    message: 'An error occurred while loading statistics',
                    requestMessage: error.statusText,
                    requestCode: error.status
                });
                if (this.chart) { this.chart.showLoading('Error on loading'); }
            }
        });
    }

    setChartItems(chartItems: 'ui' | 'api') {
        if (this.chartItems === chartItems) { return; }
        this.chartItems = chartItems;
        this.localStorage.set('dashboard_services_items', chartItems);
        this.updateChart();
    }

    updateChart() {
        if (!this.chart || !this.chartData) {
            return;
        }

        const chartServices: IService[] = this.chartData.services.filter(item => {
            if (this.chartItems === 'api' && ['MO', 'Scheduler'].includes(item.serviceName)) {
                return false;
            }
            return true;
        });

        while (this.chart.series.length > 0) {
            // Clear everything and add new series later ..
            this.chart.series[0].remove(false);
        }

        chartServices.forEach(service => {
            const data = this.chartItems === 'ui' ? service.ui : service.api;
            this.chart.addSeries({
                type: 'areaspline',
                name: service.serviceName,
                lineWidth: 1,
                marker: { radius: 2 },
                data: data
            }, false); 
        });

        this.chart.update({
            xAxis: {
                tickInterval: this.chartData.tickInterval,
                labels: { step: this.chartData.labelStep }
            }
        }, true);
    }

    setChart(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.users && changes.users.previousValue !== undefined) ||
            (changes.from && changes.from.previousValue !== undefined) ||
            (changes.to && changes.to.previousValue !== undefined)
        ) {
            this.update();
        }
    }
}
